@import "fonts/fonts.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

#overflow-location {
  width: 430px;
  position: absolute;
  top: 0;
  background: none;
}

#overflow-location .editor-widget {
  left: 0 !important;
  top: 0 !important;
}
